import FurtherInformationForm from 'components/FurtherInformationForm/FurtherInformationForm';
import { CustomQuestionItem } from 'interfaces';
import { Actions, trackAction } from 'utils/amplitude';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
import { useMemo } from 'react';
import { addAttendeesToTheForm, executeScroll } from '../CheckoutDetailsUtils';

interface IPreCheckoutInfoFormProps {
  userToken?: string;
}

const PreCheckoutInfoForm: React.FC<IPreCheckoutInfoFormProps> = ({
  userToken,
}: IPreCheckoutInfoFormProps) => {
  const { basket, steps, activeStep, setActiveStep, basketAnswerQuestionsMutation, scrollRef } =
    useCheckoutDetailsContext();

  const supplierName = basket.activity.supplier.name;

  const questionsWithAttendees = useMemo(() => {
    return addAttendeesToTheForm(basket);
  }, [basket]);

  const handleFICFormSubmission = async (completedQuestions: CustomQuestionItem[]) => {
    const getAttendeeAnswers = (filledQuestion: CustomQuestionItem) => {
      return filledQuestion.attendeeAnswers?.map((attendeeAnswer) => {
        return {
          attendeeId: attendeeAnswer.attendeeId,
          answer: attendeeAnswer.answer,
        };
      });
    };
    const filteredCompleteQuestions = completedQuestions.map((question) => {
      return {
        id: question.id,
        ...(question.question.isPerAttendee
          ? {
              attendeeAnswers: getAttendeeAnswers(question),
            }
          : {
              bookerAnswer: question.bookerAnswer,
            }),
      };
    });

    await basketAnswerQuestionsMutation({
      variables: {
        input: {
          id: basket.id,
          questionAnswers: filteredCompleteQuestions,
        },
      },
    });

    executeScroll(scrollRef);
    setActiveStep(steps[steps.indexOf(activeStep) + 1]);
    trackAction(Actions.PRE_CHECKOUT_SUBMIT_INFORMATION);
  };

  return (
    <>
      {questionsWithAttendees && questionsWithAttendees.length > 0 && (
        <FurtherInformationForm
          userToken={userToken}
          precheckout
          onPrecheckoutSubmit={handleFICFormSubmission}
          customQuestions={questionsWithAttendees}
          supplierName={supplierName}
          steps={steps}
          activeStep={activeStep}
        />
      )}
    </>
  );
};

export default PreCheckoutInfoForm;
