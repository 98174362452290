import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useMemo,
  useRef,
  MutableRefObject,
} from 'react';
import {
  BasketConfirmAttendeesInput,
  BasketSelectAddonsMutationInput,
  PreCheckoutBasket,
  CustomQuestionAttendeeAnswer,
  PreCheckoutBasketAttendee,
} from 'interfaces';
import { ActivityTypeEnum, BasketStatus, StepperEnum } from 'enums';
import { getSteps } from 'components/CheckoutDetails/CheckoutDetailsUtils';
import getBasket from 'graphql/queries/getBasket';
import { useMutation } from '@apollo/client';
import {
  BasketConfirmAttendees,
  BasketAnswerQuestions,
  BasketSelectAddons,
  BasketFinalize,
} from 'graphql/mutations';
import createChild from 'graphql/mutations/createChild';
import { Child } from 'types';

interface BasketConfirmAttendeesMutationVariables {
  variables: {
    input: BasketConfirmAttendeesInput;
  };
}

interface BasketAnswerQuestionsMutationVariables {
  variables: {
    input: {
      id: string;

      questionAnswers: {
        id: string;
        attendeeAnswers?: CustomQuestionAttendeeAnswer[] | null;
        bookerAnswer?: string | null;
      }[];
    };
  };
}

interface BasketSelectAddonsMutationVariables {
  variables: {
    input: BasketSelectAddonsMutationInput;
  };
}

interface BasketFinalizeMutationVariables {
  variables: {
    input: {
      id: string;
      isMarketingConsentGiven: boolean;
      supplierLinksAccepted: boolean | null;
      supplierMarketingConsentGiven?: boolean;
    };
  };
}

interface BasketUpdateResponse {
  id: string;
  status: BasketStatus;
}

export interface ICheckoutDetailsContext {
  basket: PreCheckoutBasket;
  hasAddOns: boolean;
  isBlockTrialCheckout: boolean;
  steps: StepperEnum[];
  preCheckoutFormRequired: boolean;
  isSubscription: boolean;
  isLoggedIn: boolean;
  eligibleAttendees: Child[];
  setEligibleAttendees: Dispatch<SetStateAction<Child[]>>;

  activeStep: StepperEnum;
  setActiveStep: Dispatch<SetStateAction<StepperEnum>>;
  soldOut: boolean;
  setSoldOut: Dispatch<SetStateAction<boolean>>;

  erroredAccordions: string[];
  setErroredAccordions: Dispatch<SetStateAction<string[]>>;
  accordionOpenId: string | null;
  setAccordionOpenId: Dispatch<SetStateAction<string | null>>;

  showAgeCheckModal: boolean;
  setShowAgeCheckModal: Dispatch<SetStateAction<boolean>>;
  openPonchoModal: boolean;
  setOpenPonchoModal: Dispatch<SetStateAction<boolean>>;
  openLeavingModal: boolean;
  setOpenLeavingModal: Dispatch<SetStateAction<boolean>>;

  checkoutLoading: boolean;
  setCheckoutLoading: Dispatch<SetStateAction<boolean>>;

  basketConfirmAttendeesMutation: (
    input: BasketConfirmAttendeesMutationVariables,
  ) => Promise<BasketUpdateResponse | null>;
  basketSelectAddonsMutation: (
    input: BasketSelectAddonsMutationVariables,
  ) => Promise<BasketUpdateResponse | null>;
  basketFinalizeMutation: (
    input: BasketFinalizeMutationVariables,
  ) => Promise<BasketUpdateResponse | null>;
  basketAnswerQuestionsMutation: (
    input: BasketAnswerQuestionsMutationVariables,
  ) => Promise<BasketUpdateResponse | null>;
  basketMutationLoading: boolean;

  createAttendeeForGuardianMutation: (
    input: PreCheckoutBasketAttendee,
  ) => Promise<PreCheckoutBasketAttendee | null>;

  scrollRef?: MutableRefObject<HTMLDivElement | null>;
}

export const CheckoutDetailsContext = createContext<ICheckoutDetailsContext | undefined>(undefined);

export const CheckoutDetailsProvider: React.FC<{
  children?: React.ReactNode;
  basket: PreCheckoutBasket;
  userToken?: string;
  registeredAttendees: Child[];
}> = ({ children, basket, userToken, registeredAttendees }) => {
  const hasAddOns = basket.tickets.some((ticket) => ticket.hasAddonOptions);
  const isBlockTrialCheckout = basket.tickets.some((ticket) => ticket.blockTrialSelected);
  const preCheckoutFormRequired = Boolean(
    basket.activity.supplier?.additionalInformationCaptureEnabled &&
      basket.questionAnswers[0]?.requiredBeforeCheckout,
  );
  const steps = getSteps(hasAddOns, preCheckoutFormRequired, isBlockTrialCheckout);
  const isSubscription = basket.activity?.activityType === ActivityTypeEnum.SUBSCRIPTION;

  const isLoggedIn = useMemo(() => {
    return Boolean(userToken);
  }, [userToken]);
  const [eligibleAttendees, setEligibleAttendees] = useState<Child[]>(
    isLoggedIn ? registeredAttendees : basket?.guest?.children || [],
  );

  const [activeStep, setActiveStep] = useState<StepperEnum>(basket.step);
  const [soldOut, setSoldOut] = useState(false);

  //Error states
  const [erroredAccordions, setErroredAccordions] = useState<string[]>([]);
  const [accordionOpenId, setAccordionOpenId] = useState<string | null>('tickets.0'); // set first ticket to be open

  //Page modal states
  const [showAgeCheckModal, setShowAgeCheckModal] = useState(false);
  const [openPonchoModal, setOpenPonchoModal] = useState(false);
  const [openLeavingModal, setOpenLeavingModal] = useState(false);

  //loading states
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  // Mutations
  const [basketConfirmAttendees, { loading: basketConfirmAttendeesLoading }] = useMutation(
    BasketConfirmAttendees,
    {
      onError: (error) => {
        console.log(error);
      },
      refetchQueries: [
        {
          query: getBasket,
          variables: { basketId: basket.id },
          context: {
            ...(isLoggedIn && {
              headers: {
                Authorization: `${userToken}`,
              },
            }),
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setEligibleAttendees(basket?.guardian?.children || basket?.guest?.children || []);
      },
    },
  );

  const basketConfirmAttendeesMutation = async (input: BasketConfirmAttendeesMutationVariables) => {
    const { data } = await basketConfirmAttendees({
      ...input,
      context: {
        ...(isLoggedIn && {
          headers: {
            Authorization: `${userToken}`,
          },
        }),
      },
    });

    if (!data) {
      return null;
    } else {
      return data.basketConfirmAttendees as BasketUpdateResponse;
    }
  };

  const [basketSelectAddons, { loading: basketSelectAddonsLoading }] = useMutation(
    BasketSelectAddons,
    {
      onError: (error) => {
        console.log(error);
      },
      refetchQueries: [
        {
          query: getBasket,
          variables: { basketId: basket.id },
          context: {
            ...(isLoggedIn && {
              headers: {
                Authorization: `${userToken}`,
              },
            }),
          },
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const basketSelectAddonsMutation = async (input: BasketSelectAddonsMutationVariables) => {
    const { data } = await basketSelectAddons({
      ...input,
      context: {
        ...(isLoggedIn && {
          headers: {
            Authorization: `${userToken}`,
          },
        }),
      },
    });
    if (!data) {
      return null;
    } else {
      return data.basketSelectAddons as BasketUpdateResponse;
    }
  };

  const [basketFinalize, { loading: basketFinalizeLoading }] = useMutation(BasketFinalize);

  const basketFinalizeMutation = async (input: BasketFinalizeMutationVariables) => {
    const { data } = await basketFinalize({
      ...input,
      context: {
        ...(isLoggedIn && {
          headers: {
            Authorization: `${userToken}`,
          },
        }),
      },
    });

    if (!data) {
      return null;
    } else {
      return data.basketFinalize as BasketUpdateResponse;
    }
  };

  const [basketAnswerQuestions, { loading: basketAnswerQuestionsLoading }] = useMutation(
    BasketAnswerQuestions,
    {
      onError: (error) => {
        console.log(error, 'Error');
      },
    },
  );

  const basketAnswerQuestionsMutation = async (input: BasketAnswerQuestionsMutationVariables) => {
    const { data } = await basketAnswerQuestions({
      ...input,
      context: {
        ...(isLoggedIn && {
          headers: {
            Authorization: `${userToken}`,
          },
        }),
      },
    });

    if (!data) {
      return null;
    } else {
      return data.basketAnswerQuestions as BasketUpdateResponse;
    }
  };

  const basketMutationLoading =
    basketConfirmAttendeesLoading ||
    basketSelectAddonsLoading ||
    basketFinalizeLoading ||
    basketAnswerQuestionsLoading;

  const [crateAttendeeForGuardian] = useMutation(createChild, {
    onError: (error) => {
      console.log(error);
    },
    refetchQueries: [
      {
        query: getBasket,
        variables: { basketId: basket.id },
        context: {
          ...(isLoggedIn && {
            headers: {
              Authorization: `${userToken}`,
            },
          }),
        },
      },
    ],
    awaitRefetchQueries: true,
    //update eligibleAttendees with newly added
    onCompleted: () => {
      setEligibleAttendees(basket?.guardian?.children || []);
    },
  });

  const createAttendeeForGuardianMutation = async (input: PreCheckoutBasketAttendee) => {
    const { data } = await crateAttendeeForGuardian({
      variables: {
        child: { ...input },
      },
      context: {
        ...(isLoggedIn && {
          headers: {
            Authorization: `${userToken}`,
          },
        }),
      },
    });

    if (!data) {
      return null;
    } else {
      return data.childCreate.child;
    }
  };

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const values: ICheckoutDetailsContext = {
    basket,
    hasAddOns,
    isBlockTrialCheckout,
    steps,
    preCheckoutFormRequired,
    isSubscription,
    isLoggedIn,
    eligibleAttendees,
    setEligibleAttendees,

    activeStep,
    setActiveStep,
    soldOut,
    setSoldOut,

    showAgeCheckModal,
    setShowAgeCheckModal,
    openPonchoModal,
    setOpenPonchoModal,
    openLeavingModal,
    setOpenLeavingModal,

    erroredAccordions,
    setErroredAccordions,
    accordionOpenId,
    setAccordionOpenId,

    checkoutLoading,
    setCheckoutLoading,

    basketConfirmAttendeesMutation,
    basketAnswerQuestionsMutation,
    basketSelectAddonsMutation,
    basketFinalizeMutation,
    basketMutationLoading,

    createAttendeeForGuardianMutation,

    scrollRef,
  };

  return (
    <CheckoutDetailsContext.Provider value={values}>{children}</CheckoutDetailsContext.Provider>
  );
};
export const useCheckoutDetailsContext = (): ICheckoutDetailsContext => {
  const context = useContext(CheckoutDetailsContext);
  if (!context) {
    throw new Error('useCheckoutDetailsContext must be used within a CheckoutDetailsProvider');
  }
  return context;
};
